import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
{
    path: 'home',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: []
},
{
    path: 'patients',
    title: 'Patient Census',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'usergroup-add',
    submenu: []
},
{
    path: 'patients/pending/true',
    title: 'Patients Pending Reading',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-switch',
    submenu: []
},
{
    path: 'not-addressed-readings/list',
    title: 'Readings Not Addressed',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'read',
    submenu: []
},
{
    path: 'newly-registered-patients/list',
    title: 'New Patients',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-add',
    submenu: []
},
{
    path: 'patient-enrollment/pending',
    title: 'Pending Enrollments',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'solution',
    submenu: []
},
// {
//     path: 'patient-enrollment/quick',
//     title: 'Quick Enrollment',
//     iconType: 'nzIcon',
//     iconTheme: 'outline',
//     icon: 'rocket',
//     submenu: []
// },
{
    path: 'referrals',
    title: 'Referrals',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'unordered-list',
    submenu: []
},
{
    path: 'patient-enrollment/archive-patients',
    title: 'Archives',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-delete',
    submenu: []
},
{
    path: '',
    title: 'Reporting',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    submenu: [
    // {
        //     path: 'reports/outlier',
        //     title: 'Outlier Reports',
        //     iconType: 'nzIcon',
        //     iconTheme: 'outline',
        //     icon: '',
        //     submenu: []
        // },
        {
            path: 'reports/admissions',
            title: 'Admissions Report',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: '',
            submenu: []
        },
        {
            path: '',
            title: 'Billing Report',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: '',
            submenu: [
            {
                path: '',
                title: 'Medicare',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: '',
                submenu: [
                {
                    path: 'reports/ccm-billing',
                    title: 'CCM',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: '',
                    submenu: []
                },
                {
                    path: 'reports/billing',
                    title: 'RPM',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: '',
                    submenu: []
                },
                ]
            },
            {
                path: '',
                title: 'Medicaid',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: '',
                submenu: [
                {
                    path: 'reports/medicaid-billing',
                    title: 'RPM',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: '',
                    submenu: []
                },
                ]
            }
            ]
        },
        // {
            //     path: 'reports/billing-stats',
            //     title: 'Billing Stats',
            //     iconType: 'nzIcon',
            //     iconTheme: 'outline',
            //     icon: '',
            //     submenu: []
            // },
            {
                path: 'reports/outcomes-v2',
                title: 'Blood Pressure Population Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: '',
                submenu: []
            },
            {
                path: '',
                title: 'Care Management',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: '',
                submenu: [
                {
                    path: '',
                    title: 'RPM',
                    iconType: 'nzIcon',
                    iconTheme: 'outline',
                    icon: '',
                    submenu: [
                    {
                        path: 'reports/readings-compliance',
                        title: 'Readings',
                        iconType: 'nzIcon',
                        iconTheme: 'outline',
                        icon: '',
                        submenu: []
                    },
                    // { test build
                        //     path: 'reports/minutes-compliance',
                        //     title: 'Minutes',
                        //     iconType: 'nzIcon',
                        //     iconTheme: 'outline',
                        //     icon: '',
                        //     submenu: []
                        // }
                        ]
                    },
                    // {
                        //     path: '',
                        //     title: 'CCM',
                        //     iconType: 'nzIcon',
                        //     iconTheme: 'outline',
                        //     icon: '',
                        //     submenu: [
                        //         {
                            //             path: 'reports/minutes-compliance',
                            //             title: 'Performance',
                            //             iconType: 'nzIcon',
                            //             iconTheme: 'outline',
                            //             icon: '',
                            //             submenu: []
                            //         }
                            //     ]
                            // }
                            ]
                        },
                        {
                            path: 'reports/non-adherence',
                            title: 'Non-Adherence Report',
                            iconType: 'nzIcon',
                            iconTheme: 'outline',
                            icon: '',
                            submenu: []
                        },
                        {
                            path: 'reports/outcomes',
                            title: 'Outcome Report',
                            iconType: 'nzIcon',
                            iconTheme: 'outline',
                            icon: '',
                            submenu: []
                        },
                        {
                            path: 'reports/performance-score-card',
                            title: 'Performance Score Card',
                            iconType: 'nzIcon',
                            iconTheme: 'outline',
                            icon: '',
                            submenu: []
                        },
                        {
                            path: 'reports/performance-score-card-v2',
                            title: 'Referrals Score Card',
                            iconType: 'nzIcon',
                            iconTheme: 'outline',
                            icon: '',
                            submenu: []
                        },
                        {
                            path: 'reports/progress-card',
                            title: 'Provider Score Card',
                            iconType: 'nzIcon',
                            iconTheme: 'outline',
                            icon: '',
                            submenu: []
                        },
                        // {
                            //     path: 'reports/risk-stratifying',
                            //     title: 'Risk Stratifying Reports',
                            //     iconType: 'nzIcon',
                            //     iconTheme: 'outline',
                            //     icon: '',
                            //     submenu: []
                            // },
                            ]
                        },
                        // {
                            //     path: '',
                            //     title: 'Multi Level Menu',
                            //     iconType: 'nzIcon',
                            //     iconTheme: 'outline',
                            //     icon: 'appstore',
                            //     submenu: [
                            //         { 
                                //             path: '',
                                //             title: 'Level 1', 
                                //             iconType: '', 
                                //             icon: '',
                                //             iconTheme: '',
                                //             submenu: [
                                //                 {
                                    //                     path: '',
                                    //                     title: 'Level 2',
                                    //                     iconType: 'nzIcon',
                                    //                     iconTheme: 'outline',
                                    //                     icon: '',
                                    //                     submenu: []
                                    //                 }
                                    //             ]
                                    //         }
                                    //     ]
                                    // }
                                    ];
